import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Universal Web UI Kit | Uizard"
    category="Website Templates"
    url="/templates/website-templates/universal-web-ui-kit/"
    metaDescription="The Uizard universal web UI kit is a modular front-end framework for developing fast and powerful web interfaces. Design your web UI now."
    description="
    h2:Uizard's universal web UI kit
    <br/>
    Our universal web UI kit is a lightweight and modular front-end framework for developing fast and powerful web interfaces. Customize and create your own web prototypes rapidly for endless use cases or make use of pre-built screens to expedite your build.
    <br/>
    h3:Web UI design just got a whole lot easier
    <br/>
    Web design made easy with our UI Kit. Instead of building from the ground up, you can use our pre-built web UI components to <a:https://uizard.io/prototyping/>rapidly prototype</a> and iterate. Teamworking has also been made easier than ever before with our smart sharing features, meaning that you can build collaboratively in real time!
    <br/>
    h3:Build web designs quickly and easily
    <br/>
    Uizard <a:https://uizard.io/templates/>UI templates</a> come with everything you could need for your design project. To get started, simply select the universal web UI kit and get to work. Whether you are building an e-commerce site or something a little more niche, our web UI kit has everything you could possibly need.
    "
    pages={[
      "All the pre-built screens you might need to build your web design from initial sign-up screens to e-commerce, finance, onboarding and profiles screens",
      "Easy customization so you can adapt, update, and tweak your designs to suit your own web design project",
    ]}
    projectCode="BoRM7yAG9Yi4AqVZ7JZX"
    img1={data.image1.childImageSharp}
    img1alt="web ui kit design template cover"
    img2={data.image2.childImageSharp}
    img2alt="web ui kit design template dashboard"
    img3={data.image3.childImageSharp}
    img3alt="web ui kit design template ecom screen"
    img4={data.image4.childImageSharp}
    img4alt="web ui kit design template profile screens"
    img5={data.image5.childImageSharp}
    img5alt="web ui kit design template onboarding screen"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/web-ui-kit/web-ui-kit-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/web-ui-kit/web-ui-kit-dashboard.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/web-ui-kit/web-ui-kit-ecom.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/web-ui-kit/web-ui-kit-profile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/web-ui-kit/web-ui-kit-onboarding.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
